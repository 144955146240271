
import React from 'react';
import {withRouter} from "react-router-dom";
import {queryApiGet, queryApiPost} from "../api";
import ErrorComponent from "./error";
import deployEventsTrackerService from "./../services/deployEventsTrackerService"
import Button from '@mui/material/Button';

const OPERATION_MODE_UPDATE = 'update'
const OPERATION_MODE_DEPLOY = 'deploy'
const OPERATION_MODE_SWITCH = 'switch'
const OPERATION_MODE_DEPLOY_TEST_VARIATION = 'deploy_test_variation'

class DeployInterface extends React.Component {
    static defaultProps = {
        operationMode: OPERATION_MODE_UPDATE
    }

    constructor(props) {
        super(props);

        this.state = {
            operationInProcess: true,
            currentOperationDescription: 'Loading changes description',
            stagingPrepared: false,
            versionOnStaging: null,
            deployComplete: false,
            errorOccurred: false,
            comparison: null,
            runtimeValidationFailed: false,
            runtimeValidationFailedMessage: null,
        };

        this.handleDeployToStagingButtonClick = this.handleDeployToStagingButtonClick.bind(this);
        this.handleDeployToProdButtonClick = this.handleDeployToProdButtonClick.bind(this);
    }

    componentDidMount() {
        this.fetchCommits()
    }

    fetchCommits() {
        let url

        if (this.props.operationMode === OPERATION_MODE_UPDATE) {
            url = `/api/getCmpCommitMessages?siteId=${this.props.siteId}`

            if (this.props.variation) {
                url += '&variation=' + this.props.variation
            }

        } else {
            url = `/api/getCmpCommitMessages?siteId=${this.props.siteId}&settingsBranch=${this.props.settingsBranch}`
        }

        if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
            deployEventsTrackerService.trackCommitsFetchStarted();
        }

        queryApiGet(url).then(data => {
            if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
                deployEventsTrackerService.trackCommitsFetchFinished();
            }

            this.setState({
                operationInProcess: false,
                comparison: data
            })}
        ).catch(er => {
            this.setErrorState(er)

            if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
                deployEventsTrackerService.trackCommitsFetchFailed(er)
            }
        })
    }

    setErrorState(errorMessage) {
        const res = [...this.state.errorMessages || [], errorMessage]

        this.setState({errorMessages: res})
    }

    handleDeployToStagingButtonClick () {
        let url

        if (this.props.operationMode !== OPERATION_MODE_SWITCH) {
            this.setState({
                operationInProcess: true,
                currentOperationDescription: 'Uploading new version to staging env'
            })
        } else {
            this.setState({
                operationInProcess: true,
                currentOperationDescription: 'Switching to master'
            })
        }

        if (this.props.operationMode === OPERATION_MODE_UPDATE) {
            url =  `/api/updateSettingsToStaging?siteId=${this.props.siteId}`

            if (this.props.variation) {
                url += '&variation=' + this.props.variation
            }
        } else if(this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
            if (!this.state.runtimeValidationFailed) {
                deployEventsTrackerService.trackDeployToStagingStarted();
            }

            url = `/api/updateLogicAndSettingsToStaging?siteId=${this.props.siteId}&logic=${this.props.logic}&settingsBranch=${this.props.settingsBranch}`
        } else {
            url = `/api/updateSettingsToStaging?siteId=${this.props.siteId}&settingsBranch=${this.props.settingsBranch}`
        }

        if (this.state.runtimeValidationFailed) {
            url += '&runtimeValidationDisabled=true'
        }

        queryApiPost(url).then(versionInfo => {
            if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
                deployEventsTrackerService.trackDeployToStagingFinished()
            }

            this.setState({
                operationInProcess: false,
                stagingPrepared: true,
                runtimeValidationFailed: false,
                runtimeValidationFailedMessage: null,
                versionOnStaging: versionInfo
            })
        }).catch(err => {
            if (err.runtimeValidationFailed) {
                this.setState({
                    runtimeValidationFailed: true,
                    runtimeValidationFailedMessage: err.errorMessage,
                    operationInProcess: false,
                    stagingPrepared: false,
                })

                document.getElementById('actionBlock')?.scrollIntoView({behavior: 'smooth'})

                return
            }

            if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
                deployEventsTrackerService.trackDeployToStagingFailed(err.errorMessage)
            }

            this.setErrorState(err)
        })
    }

    handleDeployToProdButtonClick () {
        this.setState({
            operationInProcess: true,
            currentOperationDescription: 'Uploading the version from staging to prod'
        })

        let url = `/api/setStagingToProd?siteId=${this.props.siteId}`

        if (this.props.variation) {
            url += '&variation=' + this.props.variation
        }

        if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
            deployEventsTrackerService.trackDeployToProdStarted()
        }

        queryApiPost(url).then(
            () => {
                if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
                    deployEventsTrackerService.trackDeployToProdFinished()
                }

                this.setState({
                    operationInProcess: false,
                    deployComplete: true
                })

                if (this.props.onDeployComplete) {
                    setTimeout(() => {
                        this.props.onDeployComplete(this.state.versionOnStaging)
                    }, 1000)
                }
            }
        ).catch((error) => {
            if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
                deployEventsTrackerService.trackDeployToProdFailed(error.errorMessage)
            }

            this.setErrorState(error)
        })
    }
    render() {
        let actionBlock

        if (this.state.errorMessages?.length) {
            actionBlock = <ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>
        } else if (this.state.operationInProcess) {
            actionBlock = <span>{this.state.currentOperationDescription}, please wait...</span>
        } else if(this.state.runtimeValidationFailed) {
            actionBlock = (
                <div style={{marginBottom: '40px'}}>
                    <div style={{color: 'red'}}>
                        Runtime validation failed with message: {this.state.runtimeValidationFailedMessage}
                    </div>
                    <div style={{color: 'red', marginTop: '20px'}}>
                        This might indicate a bug or a problem. If you still want to deploy please test this build thoroughly!
                    </div>

                    <Button variant={"outlined"}
                            onClick={this.handleDeployToStagingButtonClick.bind(this)}
                            style={{
                                height: '60px',
                                fontSize: 25,
                                color: '#ffcc00',
                                borderColor: '#ffcc00',
                                marginTop: '40px'
                            }}
                    >
                        Deploy to staging. I will double-check everything!
                    </Button>
                </div>
            )
        }
        else if (!this.state.stagingPrepared) {
            actionBlock = <button onClick={this.handleDeployToStagingButtonClick} style={{width: '250px', height: '60px', fontSize: 25}}>
                Deploy to staging
            </button>
        } else if (this.state.deployComplete) {
            actionBlock = <span style={{fontSize: 25, backgroundColor: '#009933', color: 'Yellow'}}>Deploy complete!</span>
        } else {
            actionBlock = <button onClick={this.handleDeployToProdButtonClick} style={{width: '250px', height: '60px', fontSize: 25}}>Deploy to prod</button>
        }

        const stagingInfo = this.state.stagingPrepared ?
            (
                <div>
                    Version on staging:<br />
                    Logic: {this.props.logic ? this.props.logic : this.state.versionOnStaging.logic.commitish} / {this.props.logicHash ? this.props.logicHash : this.state.versionOnStaging.logic.hash.substring(0, 6)}<br />
                    Settings: {this.props.settingsBranch ? this.props.settingsBranch : this.state.versionOnStaging.settings.commitish} / { this.props.settingsHashBranch ? this.props.settingsHashBranch : this.state.versionOnStaging.settings.hash.substring(0, 6)}<br />
                </div>
            ):
            ''

        let actionStr

        if (this.props.operationMode === OPERATION_MODE_UPDATE) {
            actionStr = `Updating settings for ${this.props.siteId}${this.props.variation ? ', variation ' + this.props.variation : ''}`
        } else if (this.props.operationMode === OPERATION_MODE_DEPLOY_TEST_VARIATION) {
            actionStr = `Deploying test variation ${this.props.variation} for A/B experiment on ${this.props.siteId}. Logic branch based on ${this.props.logic} Settings branch based on ${this.props.settingsBranch}`
        } else if (this.props.operationMode === OPERATION_MODE_SWITCH) {
            actionStr = `Switching to master on ${this.props.siteId}. Settings branch based on origin/master`
        }

        return (
            <div style={{height: '600px'}}>
                <div style={{textAlign: 'center', fontSize: 70}}>
                    {actionStr}
                </div>
                {stagingInfo}
                {
                    this.state.comparison &&
                    <ComparisonInfo changes={this.state.comparison} />
                }
                <div style={{textAlign: 'center', marginTop: '20px', paddingBottom: '30px'}} id="actionBlock">
                    {actionBlock}
                </div>
            </div>
        )
    }
}

class UpdateSettings extends React.Component {
    render() {
        return <DeployInterface
            operationMode={OPERATION_MODE_UPDATE}
            siteId={this.props.match.params.siteId}
            variation={this.props.match.params.variation}
        />
    }
}


class SwitchToMaster extends React.Component {
    render() {
        return <DeployInterface operationMode={OPERATION_MODE_SWITCH} settingsBranch={'master'} siteId={this.props.match.params.siteId}/>
    }
}

class updateVariationBranch extends React.Component {
    render() {
        return <DeployInterface operationMode={OPERATION_MODE_DEPLOY_TEST_VARIATION} logic={this.props.match.params.logic} settingsBranch={this.props.match.params.settingsBranch} siteId={this.props.match.params.siteId}/>
    }
}


class ComparisonInfo extends React.Component {
    render() {
        const columnNames = ['changesAdded', 'changesRemoved'].filter(t => this.props.changes[t].length)

        const columnCaptions = {
            'changesAdded': 'Changes that will be added:',
            'changesRemoved': 'Changes that will be removed:'
        }

        const columns = columnNames.map(
            t => {
                const changesMessages = this.props.changes[t].map(
                    (m, i) => (
                        <div key={i}>
                            {m}
                        </div>
                    )
                )

                return (
                    <td key={t} style={{verticalAlign: 'top'}} width={columnNames.length === 2 ? '50%': '100%'}>
                        {columnCaptions[t]}
                        {changesMessages}
                    </td>
                )
            }
        )

        return (
            <div>
                <div>Changes that will be deployed:</div>
                <table border={1} width={"100%"}>
                    <tbody>
                        <tr>
                            {columns}
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

const UpdateSettingsRoute = withRouter(UpdateSettings)
const SwitchToMasterRoute = withRouter(SwitchToMaster)
const updateVariationBranchRoute = withRouter(updateVariationBranch)

export {
    UpdateSettingsRoute,
    SwitchToMasterRoute,
    updateVariationBranchRoute,
    DeployInterface,
    OPERATION_MODE_DEPLOY,
    OPERATION_MODE_UPDATE,
    OPERATION_MODE_SWITCH,
    OPERATION_MODE_DEPLOY_TEST_VARIATION
}
