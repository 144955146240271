
import React from 'react';

import {queryApiGet, queryApiPost} from '../api.js'
import ErrorComponent from "./error";
import Button from "@mui/material/Button";

class DeployNewSite extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deployCompleted: false,
            errorOccurred: false,
            loadingTags: true,
            logicVersions: null,
            logicVersion: null,
            siteId: null,
            disableRuntimeValidation: false,
            loadingNotDeployedSites: true,
            notDeployedSites: null,
            deployInProcess: false
        }

    }

    componentDidMount() {
        this.loadTags()
        this.loadNotDeployedSites()
    }

    loadTags() {
        this.setState({
            loadingTags: true
        })

        queryApiGet('/api/tagsNames').then((tags) => {
            this.setState({
                loadingTags: false,
                logicVersions: tags.slice(0, 50)
            })
        }).catch(er => this.setErrorState(er))
    }

    setErrorState(errorMessage) {
        const res = [...this.state.errorMessages || [], errorMessage]

        this.setState({errorMessages: res})
    }

    loadNotDeployedSites() {
        this.setState({
            loadingNotDeployedSites: true
        })

        queryApiGet('/api/getNewSites').then((sites) => {
            this.setState({
                loadingNotDeployedSites: false,
                notDeployedSites: sites
            })
        }).catch(er => this.setErrorState(er))
    }

    handleDeployFormSubmit(e) {
        e.preventDefault()

        if (e?.target?.elements) {
            const controls = Array.from(e.target.elements)

            const siteId = controls.find(el => el.id === 'select_website').value
            const logicVersion = controls.find(el => el.id === 'select_logic_version').value
            this.setState({siteId, logicVersion})
        }

        this.setState({deployInProcess: true})

        // #Todo По какой-то причине, после того как добавил setState в участке кода выше, появилась необходимость добавить timeout
        setTimeout(() => {
            queryApiPost('/api/deployNewSite', {
                siteId: this.state.siteId,
                logicVersion: this.state.logicVersion,
                disableRuntimeValidation: this.state.runtimeValidationFailed
            }).then(() => {
                this.setState({
                    deployInProcess: false,
                    deployCompleted: true,
                    runtimeValidationFailed: false,
                    runtimeValidationMessage: null,
                })
            }).catch(er => {
                if (er.runtimeValidationFailed) {
                    this.setState({
                        deployInProcess: false,
                        runtimeValidationFailed: true,
                        runtimeValidationMessage: er.errorMessage,
                    })

                    return
                }

                this.setErrorState(er)
            })
        })
    }

    render () {
        if (this.state.errorMessages?.length) {
            return (<ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>)
        }

        if (this.state.loadingTags || this.state.loadingNotDeployedSites || this.state.deployInProcess) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        }

        if (this.state.runtimeValidationFailed) {
            return (
                <div>
                    <div style={{color: 'red'}}>
                        Runtime validation failed with message: {this.state.runtimeValidationMessage}
                    </div>

                    <div style={{color: 'red', marginTop: '20px'}}>
                        This might indicate a bug or a problem. If you still want to deploy please test this build thoroughly!
                    </div>

                    <div>
                        <Button variant={"outlined"}
                                onClick={this.handleDeployFormSubmit.bind(this)}
                                style={{
                                    height: '60px',
                                    fontSize: 25,
                                    color: '#ffcc00',
                                    borderColor: '#ffcc00',
                                    marginTop: '40px'
                                }}
                        >
                            Deploy new site anyway. I will double-check everything!
                        </Button>
                    </div>
                </div>

            )
        }

        if (this.state.deployCompleted) {
            return (
                <div style={{fontSize: 25, backgroundColor: '#009933', color: 'Yellow', marginTop: '10px'}}>Done</div>
            )
        }

        if (!this.state.notDeployedSites.length) {
            return <span style={{fontSize: 25, backgroundColor: '#cc0000', color: '#ffccff'}}>No new sites to deploy</span>
        }

        const logicVersionsOptions = this.state.logicVersions.map(
            logicVersion => <option key={logicVersion}>{logicVersion}</option>
        )

        const websitesOptions = this.state.notDeployedSites.map(
            site => <option key={site}>{site}</option>
        )

        return (
            <form onSubmit={this.handleDeployFormSubmit.bind(this)}>
                <table cellPadding={20}>
                    <tbody>
                    <tr>
                        <td>
                            <label htmlFor={'select_website'}>Choose website</label>
                        </td>
                        <td width={'200px'}>
                            <select id={'select_website'} name="siteId" style={{width: '70%', fontSize: 20}} required>
                                {websitesOptions}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor={'select_logic_version'}>Choose logic version</label>
                        </td>
                        <td>
                            <select id={'select_logic_version'} name="logicVersion" style={{width: '70%', fontSize: 20}} required>
                                {logicVersionsOptions}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button type={'submit'} style={{width: '250px', height: '60px', fontSize: 25, margin: '5px'}}>Deploy</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
        )
    }
}

export default DeployNewSite
